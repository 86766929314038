<template>
  <div id="app" class="font-poppins text-white bg-primary">
    <NavBar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script setup>
import NavBar from '@/components/Navbar';
import Footer from '@/components/Footer';
import { minecart } from 'minecart-sdk';

minecart.setApiKey(process.env.VUE_APP_MINECART_API_KEY);
</script>