<template>
  <div class="relative bottom-0 z-30 flex justify-between bg-navbar px-10 py-8 w-full">
    <div class="flex flex-col justify-between h-full" style="min-width: 15%;min-height: 122px;">
      <h1 class="font-black text-2xl md:text-3xl lg:text-4xl">ZENITE</h1>
      <div class="flex gap-2">
        <i class="pi pi-discord cursor-pointer" @click="router.push('/discord')"/>
        <i class="pi pi-twitter cursor-pointer" @click="router.push('/twitter')"/>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center h-full" style="min-height: 122px;">
      <router-link to="/store">
        <Button label="Compre seu bot" class="p-button-success p-button-rounded mb-3 font-medium text-lg lg:text-xl"></Button>
      </router-link>
      <!--<Button href="/store" class="bg-green-500 hover:bg-green-600 mb-3" rounded="xl" size="min">Compre seu bot</Button>-->
      <router-link to="/terms">
        <span class="flex cursor-pointer text-center">Termos e Condições</span>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Baskervville+SC&display=swap');
h1 {
  font-family: "Baskervville SC", serif;
  font-weight: 400;
  font-style: normal;
}
</style>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
</script>