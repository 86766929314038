import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
    plugins: [createPersistedState()],
    state: {
        user: String,
        profile: {
            username: null,
            skins: Array,
        },
    },
    getters: {
        user: state => state.user,
        profile: state => state.profile,
    },
    mutations: {
        changeUser (state, val) {
            state.user = val;
        },
        changeProfile (state, val) {
            state.profile = { username: val.username, skins: val.skins };
        },
    },
});

export default store;