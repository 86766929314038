import { useQuery } from '@tanstack/vue-query';
import { minecart } from 'minecart-sdk';
import { getProducts } from './keys';
let categories = { ranks: 343, weapons: 344, hats: 345, messangers: 346, prefixes: 347, pets: 348, emotes: 349, bundles: 350, capes: 352, others: 351 };

export const useGetProducts = (options) =>
  useQuery({
    queryKey: getProducts(),
    queryFn: () => {
      return minecart.products.all().then(products => {
          return products.filter(product => product.category === categories[options]);
      });
    },
  });
