import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/Home/index.vue';
import StoreView from '../views/Store/index.vue';
import ProductsView from '../views/Store/Products/index.vue';
import CartView from '../views/Cart/index.vue';
import ProfileView from '../views/Profile/index.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeView
    },
    {
      path: '/store',
      name: 'Store',
      component: StoreView
    },
    {
      path: '/store/:category',
      name: 'Products',
      component: ProductsView
    },
    {
      path: '/store/profile',
      name: 'StoreProfile',
      component: () => import('@/views/Store/Profile')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login')
    },
    {
      path: '/profile',
      name: 'Profile',
      component: ProfileView
    },
    {
      path: '/cart',
      name: 'Cart',
      component: CartView
    },
    {
      path: '/guides',
      name: 'Guides',
      component: () => import('@/views/Guides')
    }
  ]
});

export default router;
