import { useQuery } from '@tanstack/vue-query';
import { minecart } from 'minecart-sdk';
import { getCategories } from './keys';

let categoriesImages = [{ image: 'gem.png', url: 'ranks' },{ image: 'sword.png', url: 'weapons' }, { image: 'hat.png', url: 'hats' }, { image: 'bird.png', url: 'messengers' }, { image: 'tag.png', url: 'prefixes' }, { image: 'capibara.png', url: 'pets' }, { image: 'emote.png', url: 'emotes' }, { image: 'chest.png', url: 'bundles' }, { image: 'id.png', url: 'capes' }, { image: 'disc.png', url: 'others' }];
export const useGetCategories = (options) =>
  useQuery({
    queryKey: getCategories(),
    queryFn: async () => {
        return minecart.categories.all()
            .then(categories => {
                return categories.map(obj => ({ ...obj, image: categoriesImages[obj.order].image, url: categoriesImages[obj.order].url }));
            })
            .catch((_) => {
        return [];
      });
    },
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
