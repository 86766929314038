<template>
  <main class="flex flex-row items-center justify-center w-full h-screen">
    <div class="flex w-3/4 justify-center">
      <div v-if="!isLoadingProducts" class="grid grid-cols-4 gap-4 w-full px-20">
        <ConfirmDialog :draggable="false"/>
        <div v-for="product in productsData" :key="product.id" class="p-4 bg-navbar rounded-xl items-between">
          <img :alt="'product-' + product.id" :src="product.archive.url" class="w-3/5 mx-auto" />
          <h1 class="text-center text-xl my-2 font-jost">{{ product.name }}</h1>
          <Button :key="product.id" label="Adicionar ao carrinho" severity="contrast" outlined class="w-full" @click.prevent="addCart(product)"/>
        </div>
      </div>
      <div v-else class="w-full font-jost">
        <div class="grid grid-cols-4 gap-4 w-full">
          <div v-for="n in 8" class="flex flex-col w-full items-center mb-4 justify-center">
            <Skeleton shape="square" size="8rem" class="mb-2"/>
            <div class="self-center w-1/2 justify-center" style="flex: 1">
              <Skeleton width="100%" class="mb-2"/>
              <Skeleton width="100%" class="mb-2"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-1/5 flex flex-col p-4 bg-navbar rounded-xl items-center m-4">
      <h1 class="text-2xl font-poppins mb-4">Carrinho</h1>
      <div v-for="item in products" class="flex w-2/3 items-start text-xl font-poppins text-left">
        <h1 class="mb-2">{{ item.name }} <Tag>R${{ item.price }}</Tag></h1>
      </div>
      <Button label="Ir para o carrinho" @click="router.push('/cart')" class="w-full mt-4"/>

    </div>
  </main>
</template>

<script setup>
import Skeleton from 'primevue/skeleton';
import ConfirmDialog from 'primevue/confirmdialog';
import Button from 'primevue/button';
import { useGetProducts } from '@/services/products';
import { useConfirm } from 'primevue/useconfirm';
import router from '@/utils/router';
import { cart } from 'cart-ts';
import {computed, ref} from 'vue';
import { useStore } from 'vuex';
import Tag from 'primevue/tag';

const products = ref(cart.list());
const store = useStore();

const confirm = useConfirm();
const user = computed(() => {
  return store.getters.user;
});
let { data: productsData, isPending: isLoadingProducts } = useGetProducts(router.currentRoute.value.params.category);

const addCart = (product) => {
  cart.add({ id: product.id, name: product.name, price: product.value, quantity: 1 });
  products.value = cart.list();

  confirm.require({
    message: 'Produto adicionado ao carrinho',
    rejectProps: {
      label: 'Continuar comprando',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Carrinho',
      severity: 'success',
      outlined: true
    },
    accept: () => {
      if (user.value) router.push('/cart');
      else router.push('/store/profile');
    },
    reject: () => {
      confirm.close();
    }
  });
}
</script>