import { useQuery } from '@tanstack/vue-query';
import { minecart } from 'minecart-sdk';
import { getGateways } from './keys';

export const useGetGateways = (options) =>
  useQuery({
    queryKey: getGateways(),
    queryFn: () => {
      return minecart.gateways.all();
    },
    ...options,
  });
