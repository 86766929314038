<template>
  <main class="flex flex-row items-start justify-center w-full h-screen">
    <div class="flex flex-col w-3/4 justify-center">
      <div class="flex flex-col bg-navbar w-full rounded-xl py-4 mt-20">
        <div v-if="products.length > 0" v-for="product in products" class="flex flex-col">
          <div class="flex flex-row items-center justify-between w-full px-4">
              <div class="flex flex-row items-center gap-2">
                <h1 class="text-center text-sm my-2 font-jost">{{ product.name }}</h1>
                <Tag class="text-sm" severity="info" :value="'R$' + product.price + ',00'"/>
              </div>
              <div class="flex flex-row items-center font-roboto">
                <i class="pi pi-plus cursor-pointer text-gray-500" style="font-size: 0.8rem" @click="changeQuantity(product.id, product.quantity + 1)"/>
                <h1 class="text-center text-sm mx-2">{{ product.quantity }}</h1>
                <i class="pi pi-minus cursor-pointer mr-4 text-gray-500" style="font-size: 0.8rem" @click="changeQuantity(product.id, product.quantity - 1)"/>
                <i class="pi pi-trash text-2xl text-red-500 cursor-pointer" @click="remove(product.id)"/>
              </div>
          </div>
          <Divider v-if="products[products.length-1] !== product"/>
        </div>
        <div v-else class="flex flex-col font-jost justify-center items-center">
          <h1 class="flex text-center text-2xl m-8">Nenhum produto encontrado.</h1>
        </div>
      </div>
      <div class="flex flex-row w-full mt-12 gap-x-4">
        <div class="flex flex-row w-full bg-navbar justify-between items-center rounded-xl">
          <canvas id="renderContainer"/>
          <div class="flex flex-col justify-center items-center font-jost w-3/4 px-12">
            <h1 class="text-center text-2xl mb-1">Usuário: {{ defaultUser }}</h1>
            <h1 class="text-center text-sm text-gray-400">O item comprado será adicionado à essa conta do Minecraft.</h1>
            <Button v-if="!profile.username" class="w-2/3 mt-4" severity="info" label="Alterar usuário" outlined @click="router.push('/store/profile')"/>
            <Button v-else class="w-2/3 mt-4" severity="info" label="Desconectar" outlined @click="router.push('/logout')"/>
          </div>
        </div>
        <div class="flex flex-col w-full bg-navbar justify-center items-center rounded-xl font-jost">
          <h1 class="text-center text-xl my-2 font-jost">Total</h1>
          <h1 class="text-center text-2xl font-jost">R${{ total }},00</h1>
          <ConfirmDialog :draggable="false" group="headless">
              <template #container="{ message, rejectCallback }">
                <div class="flex flex-col items-center w-full px-8 bg-surface-0 dark:bg-surface-900 rounded">
                  <div class="flex flex-col items-end w-full mt-6">
                    <i class="pi pi-times cursor-pointer" style="color: gray;" @click="rejectCallback"/>
                  </div>
                  <p class="text-xl font-poppins mb-0 my-auto mt-4">{{ message.message }}</p>
                  <div v-if="isLoading" class="flex items-center gap-2 my-6">
                    <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s" aria-label="carregando"/>
                  </div>
                  <div v-else class="flex items-center gap-2 my-6">
                    <Button v-for="gateway in gateways" size="normal" :label="gateway" iconPos="left" :icon="'pi pi-' + gateway.toLowerCase()" severity="info" outlined @click="buyCallback(gateway)"/>
                  </div>
                </div>
              </template>
          </ConfirmDialog>
          <Button class="w-1/3 mt-4" severity="success" label="Finalizar compra" @click="selectGateway()"/>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import Divider from 'primevue/divider';
import Tag from 'primevue/tag';
import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import ProgressSpinner from 'primevue/progressspinner';
import { cart } from 'cart-ts';
import { useConfirm } from 'primevue/useconfirm';
import { onMounted, ref, computed } from 'vue';
import router from '@/utils/router';
import { useStore } from 'vuex';
import axios from '@/utils/axios';
import { useGetGateways } from '@/services/gateways';
import * as skinview3d from 'skinview3d';
import { minecart } from 'minecart-sdk';

const store = useStore();
const confirm = useConfirm();
const products = ref([]);
const total = computed(() => {
  return products.value.reduce((sum, product) => {
    return sum + (Number(product.price) * product.quantity);
  }, 0);
});
const defaultUser = ref(null);
const user = computed(() => {
  return store.getters.user;
});
const profile = computed(() => {
  return store.getters.profile;
});

const { data: gateways, isLoading } = useGetGateways();

onMounted(() => {
  if (!profile.value.username && !user.value) return router.push('/store/profile');
  if (profile.value.username) {
    defaultUser.value = profile.value.username;
    changeSkin(profile.value.skins[profile.value.skins.length-1]);
  } else {
    defaultUser.value = user.value;
    axios.get('/minecraft/body/' + user.value)
        .then(response => {
          return changeSkin(response.data.body);
        })
        .catch(async () => {
          return null;
        });
  }
  products.value = cart.list();
});

function changeSkin(skin) {
  const element = document.getElementById('renderContainer');
  const skinViewer = new skinview3d.SkinViewer({
    canvas: element,
    width: 300,
    height: 400,
    skin,
    enableControls: false,
    animation: new skinview3d.IdleAnimation(),
  });
  skinViewer.autoRotate = true;
  skinViewer.autoRotateSpeed = 0.3;
}

function remove(id) {
  cart.remove(id);

  let index = products.value.findIndex(product => product.id === id);
  products.value.splice(index, 1);
}
function changeQuantity(id, quantity) {
  if (quantity === 0) cart.remove(id);
  else cart.updateQuantity(id, quantity);

  products.value = cart.list();
}

async function selectGateway() {
  confirm.require({
    group: 'headless',
    message: 'Escolha o meio de pagamento',
    reject: () => {
      confirm.close();
    }
  });
}

async function buyCallback(gateway) {
  confirm.close();
  const items = products.value.map(({ id, quantity }) => ({
    id,
    amount: quantity,
  }));
  minecart.payment.create({
     gateway,
     username: defaultUser.value,
     items
  })
  .then(({ url }) => {
    cart.clean();

    window.location.href = url;
  })
 .catch((error) => {
   console.log("[error]", error);
 });
}
</script>