<template>
  <main class="flex flex-col items-center justify-center w-full h-screen">
    <div class="flex flex-col w-11/12 md:w-3/4 lg:w-3/5 mx-auto bg-team rounded-3xl font-poppins text-black ovf">
      <div class="relative flex flex-row w-full justify-center lg:justify-around">
        <div class="flex flex-col w-11/12 lg:w-3/5 my-4 mx-4 lg:my-10 lg:mx-10" @mouseover="stopTimer()" @mouseleave="unstopTimer()">
          <h1 class="text-2xl lg:text-3xl font-bold">CONHEÇA A EQUIPE</h1>
          <h1 class="flex text-xl lg:text-2xl mt-2 mb-1 items-center font-semibold">{{ profile.name }}
            <Tag class="ml-2" severity="contrast" style="border: 2px solid black; background: transparent; color: black;">
              <div class="flex items-center gap-2 px-1">
                <span class="text-base font-semibold">{{ profile.tag }}</span>
              </div>
            </Tag>
          </h1>
          <h2 class="text-md md:text-xl font-medium h-60 mt-2">{{ profile.description }}</h2>
          <div class="justify-center flex flex-row items-center w-full pt-6 gap-8">
            <div v-for="prof in profiles" class="hover:pb-1.5 hover:pt-0 pt-1.5">
              <img :alt="prof.avatar" @click="changeProfile(prof)" :src="'/assets/images/home/carousel/' + prof.avatar" :class="prof.name === profile.name ? 'border-solid border-3 border-white' : ''" class="w-12 h-12 transition-all duration-100 rounded"/>
            </div>
          </div>
        </div>
        <div class="flex w-2/5 items-center collapse lg:visible">
          <div :class="`w-full h-full bg-no-repeat bg-cover bg-center ${animate ? 'animate-fadeIn' : 'animate-fadeOut'}`" :style="`background-image: url('/assets/images/home/carousel/${profile.body}');`"/>
        </div>
        <div class="absolute bottom-0 w-full">
          <div class="flex bg-white pt-1.5 transition-all duration-100 overflow-hidden" :style="`width: ${time}%;`"/>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import Tag from 'primevue/tag';

const time = computed(() => {
  return timer.value*0.02;
});
const interval = ref(null);
const profile = ref({ name: 'Stein', description: '', avatar: '', body: 'stein.png' });
const profiles = ref([
    { name: 'Stein', description: 'Nascido em uma família de lobos do Skript, ele começou do zero e conseguiu alcançar o topo, criando assim a Zenite.', avatar: 'steinhead.png', body: 'stein.png', tag: 'Fundador' },
    { name: 'Kiddo', description: 'Sou Kiddo, estagiário em Zenite. Estou animado para aplicar e expandir meus conhecimentos acadêmicos em um ambiente profissional dinâmico. Com uma paixão por Minecraft, estou comprometido em obedecer as ordens de Stein e Julio, enquanto busco adquirir experiência prática e colaborar em projetos inovadores.', avatar: 'kiddohead.png', body: 'kiddo.png', tag: 'Estagiário' },
    { name: 'Julio', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac quam ac ex elementum vehicula id semper odio. Vivamus quis urna at ex convallis auctor quis vitae orci. Curabitur eu nisi a sapien hendrerit semper eu quis dolor. Nam ullamcorper mollis ullamcorper. Sed in interdum diam, a eleifend mi. Maecenas ultricies et eros laoreet sollicitudin. Vivamus hendrerit lectus ac ', avatar: 'juliohead.png', body: 'julio.png', tag: 'Desenvolvedor' },
]);
const tyler = ref({ name: 'Tyler Durden', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac quam ac ex elementum vehicula id semper odio. Vivamus quis urna at ex convallis auctor quis vitae orci. Curabitur eu nisi a sapien hendrerit semper eu quis dolor. Nam ullamcorper mollis ullamcorper. Sed in interdum diam, a eleifend mi. Maecenas ultricies et eros laoreet sollicitudin. Vivamus hendrerit lectus ac ', avatar: '', body: 'tyler.png', tag: 'Dono' });
const timer = ref(0);
const animate = ref(false);

onMounted(() => {
  profile.value = profiles.value[0];
  animate.value = true;
  interval.value = setInterval(() => {
    if (timer.value === 5000 && animate.value) {
      animate.value = false;
      setTimeout(() => {
        timer.value = 0;
        let random = Math.floor(Math.random() * 99);
        if (random === 0) return profile.value = tyler.value;
        let index = profiles.value.indexOf(profile.value);
        if (index >= 0 && index < profiles.value.length - 1) profile.value = profiles.value[index + 1];
        else profile.value = profiles.value[0];
        animate.value = true;
      }, 200);
    } else timer.value += 100;
  }, 100);
});

function changeProfile(prof) {
  animate.value = false
  setTimeout(() => {
    profile.value = prof;
    timer.value = 0;
    animate.value = true;
  }, 200);
}

function stopTimer() {
  clearInterval(interval.value);
}

function unstopTimer() {
  interval.value = setInterval(() => {
    if (timer.value === 5000 && animate.value) {
      animate.value = false;
      setTimeout(() => {
        timer.value = 0;
        let random = Math.floor(Math.random() * 99);
        if (random === 0) return profile.value = tyler.value;
        let index = profiles.value.indexOf(profile.value);
        if (index >= 0 && index < profiles.value.length - 1) profile.value = profiles.value[index + 1];
        else profile.value = profiles.value[0];
        animate.value = true;
      }, 200);
    } else timer.value += 100;
  }, 100);
}
</script>

<style scoped>
.ovf {
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}
</style>