import { useQuery } from '@tanstack/vue-query';
import axios from '@/utils/axios';
import { getProfile } from './keys';
import router from '@/utils/router';
import { useCookies } from 'vue3-cookies';
import { useStore } from 'vuex';
const { cookies } = useCookies();
const store = useStore();

export const useGetProfile = (options) =>
    useQuery({
        queryKey: getProfile(),
        queryFn: () => {
            return axios.post('/profile', {}, { headers: { Authorization: `Bearer ${cookies.get('TOKEN')}` } })
            .then(response => {
                if (store.getters.profile?.username !== response.data.username) store.commit('changeProfile', response.data);
                return response.data;
            })
            .catch(async () => {
                if (cookies.get('TOKEN')) cookies.remove('TOKEN');
                if (router.currentRoute.value.path === '/profile') await router.push('/login');
                return null;
            });
        },
        ...options,
  });
