<template>
  <main class="flex flex-row justify-center w-full h-screen">
    <div class="flex w-3/4 justify-center h-min mt-20">
      <div v-if="!isLoadingCategories" class="grid grid-cols-4 gap-4 w-full px-20">
        <div v-for="category in categoriesData" :key="category.id" class="flex flex-col p-4 bg-white rounded-xl items-center text-black cursor-pointer" @click="router.push('/store/' + category.url)">
          <img :alt="'category-' + category.id" :src="`/assets/images/store/${category.image}`" class="w-3/5 max-h-3/5" />
          <h1 class="text-center text-xl my-2 font-jost">{{ category.name }}</h1>
        </div>
      </div>
      <div v-else class="w-full font-jost">
        <div class="grid grid-cols-4 gap-4 w-full">
          <div v-for="n in 9" class="flex flex-col w-full items-center mb-4 justify-center">
            <Skeleton shape="square" size="8rem" class="mb-2"/>
            <div class="self-center w-1/2 justify-center" style="flex: 1">
              <Skeleton width="100%" class="mb-2"/>
              <Skeleton width="100%" class="mb-2"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-1/5 flex flex-col p-4 bg-navbar rounded-xl h-min m-4 mt-20">
      <h1 class="text-2xl font-poppins mb-4">Carrinho</h1>
      <div v-for="item in products" class="flex w-2/3 items-start text-xl font-poppins text-left">
        <h1 class="mb-2">{{ item.name }} <Tag>R${{ item.price }}</Tag></h1>
      </div>
      <Button label="Ir para o carrinho" @click="router.push('/cart')" class="w-full mt-4"/>
    </div>
  </main>
</template>

<script setup>
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import { useGetCategories } from '@/services/categories';
import router from '@/utils/router';
import { cart } from 'cart-ts';

const products = cart.list();
let { data: categoriesData, isPending: isLoadingCategories } = useGetCategories();
</script>