<template>
  <main class="flex flex-row items-start justify-center w-full h-screen">
    <div class="flex flex-col w-3/4 justify-center item-center bg-navbar rounded-2xl mt-20">
      <div class="flex flex-row w-full justify-center items-center">
        <div class="flex flex-col justify-center items-center border-2 border-solid m-6 border-white rounded-2xl">
          <canvas id="renderContainer" class="mt-8"/>
          <div class="flex flex-row gap-2 justify-center items-center mb-8">
            <h1 class="font-minecraft cursor-pointer" @click="passSkin('prev')"><</h1>
            <img v-for="(skin, i) in profile.skins.filter(onlyUnique)" :alt="'skin-' + i" :src="'https://mc-heads.net/avatar/' + skin.replace('http://textures.minecraft.net/texture/', '') + '.png'" :class="`h-10 cursor-pointer ${skin === defaultSkin ? 'mb-3' : ''}`" @click="changeSkin(skin)"/>
            <h1 class="font-minecraft cursor-pointer" @click="passSkin('next')">></h1>
          </div>
        </div>
        <div class="flex flex-col w-full justify-center items-center">
          <h1 class="text-center text-3xl font-black font-bebas text-white">{{ profile.username }}</h1>
          <h1 class="text-center text-sm text-gray-400">Ingressou em: {{  moment(time.firstLogin).format('L') }}</h1>
          <h1 class="text-center text-sm text-gray-400">Tempo de jogo: {{ countdown(null, Date.now()+time.totalPlayed, countdown.DEFAULTS, 2).toString() }}</h1>
          <h1 class="text-center text-sm text-gray-400">Último login: {{  moment(time.lastLogin).fromNow() }}</h1>
        </div>
      </div>
      <div class="flex flex-col w-full justify-center items-start my-10">
        <h1 class="text-center text-3xl font-black font-bebas text-white ml-6">Conexões</h1>
        <div class="flex flex-row justify-center items-center">
          <div class="flex flex-row justify-center items-center m-6 gap-4">
            <i class="pi pi-discord" style="font-size: 2rem;"/>
            <h1 class="text-center text-lg text-gray-400">Não conectado</h1>
            <i class="pi pi-sign-in" style="font-size: 2rem;"/>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full justify-center items-start my-10">
        <h1 class="text-center text-3xl font-black font-bebas text-white ml-6">Comissões</h1>
        <div class="flex flex-row justify-center items-center">
          <div class="flex flex-row justify-center items-center m-6 gap-4">
            <h1 class="text-center text-lg text-gray-400">Conecte seu Discord</h1>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full justify-center items-start my-10">
        <h1 class="text-center text-3xl font-black font-bebas text-white ml-6">Histórico de Compras</h1>
        <div class="flex flex-row justify-center items-center">
          <div class="flex flex-col justify-center items-center m-6 gap-4">
            <h1 class="text-center text-lg text-gray-400">Vip pedra - R$ 100,00</h1>
            <h1 class="text-center text-lg text-gray-400">Vip craque - R$ 150,00</h1>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import * as skinview3d from 'skinview3d';
import axios from '@/utils/axios';
import router from '@/utils/router';
import { useCookies } from 'vue3-cookies';
import moment from 'moment';
import countdown from 'countdown';
moment.locale('pt-br');
countdown.setLabels(
    ' milissegundo| segundo| minuto| hora| dia| semana| mês| ano| década| século| milênio',
    ' milissegundos| segundos| minutos| horas| dias| semanas| meses| anos| décadas| séculos| milênios',
    ' e ',
    ' + ',
    'agora');

const { cookies } = useCookies();
const profile = ref({ username: null, skins: [] });
const defaultSkin = ref(null);
const time = ref({
  firstLogin: null,
  lastLogin: null,
  totalPlayed: null,
})
const totalPlay = computed(() => {
  const futureDate = moment().add(time.value.totalPlayed, 'milliseconds');
  return moment.duration(futureDate.diff(moment()));
});

onMounted(() => {
  axios.post('/profile', {}, { headers: { Authorization: `Bearer ${cookies.get('TOKEN')}` } })
      .then(response => {
        profile.value = response.data;
        time.value = response.data.time;
        defaultSkin.value = response.data.skins[response.data.skins.length-1];
        return changeSkin(response.data.skins[response.data.skins.length-1]);
      })
      .catch(async () => {
        if (cookies.get('TOKEN')) cookies.remove('TOKEN');
        if (router.currentRoute.value.path === '/profile') return router.push('/login');
      });
});

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function changeSkin(skin) {
  defaultSkin.value = skin;
  const element = document.getElementById('renderContainer');
  new skinview3d.SkinViewer({
    canvas: element,
    width: 300,
    height: 400,
    skin: skin,
    enableControls: true,
    animation: new skinview3d.IdleAnimation(),
    nameTag: profile.value.username,
    zoom: 0.8
  });
}

function passSkin(direction) {
  const allSkins = profile.value.skins.filter(onlyUnique);
  const index = allSkins.findIndex(skin => skin === defaultSkin.value);
  if (direction === 'next') {
    if (index === allSkins.length-1) return changeSkin(allSkins[0]);
    else return changeSkin(allSkins[index+1]);
  } else {
    if (index === 0) return changeSkin(allSkins[allSkins.length-1]);
    else return changeSkin(allSkins[index-1]);
  }
}
</script>