import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import { VueQueryPlugin } from '@tanstack/vue-query';
import FocusTrap from 'primevue/focustrap';
import Tooltip from 'primevue/tooltip';
import VueCookies from 'vue3-cookies';

import '../public/assets/css/index.css';
import 'primeicons/primeicons.css';
import Aura from '@primevue/themes/aura';

import router from './utils/router.js';
import store from './utils/store.js';

const app = createApp(App);

app.use(PrimeVue, {
    theme: { preset: Aura }
});
app.use(router);
app.use(store);
app.use(VueQueryPlugin);
app.use(VueCookies);
app.use(ConfirmationService);
app.use(ToastService);
app.directive('tooltip', Tooltip);
app.directive('focustrap', FocusTrap);

app.mount('#app');