<template>
  <nav class="sticky top-0 z-50 table-fixed flex justify-between items-center py-1 px-3 w-full bg-navbar shadow-sm text-white">
    <router-link to="/" class="flex flex-row items-center my-2">
      <img alt="logo" src="/assets/images/home/logo.png" class="h-10 w-auto">
      <div class="collapse lg:visible flex flex-col justify-center items-center ml-2">
        <h1 class="text-3xl md:text-4xl lg:text-2xl font-black font-bebas text-white">ZENITE</h1>
        <h1 class="text-3xl md:text-4xl lg:text-sm font-black font-bebas text-white">ROLEPLAY</h1>
      </div>
    </router-link>
    <ul class="collapse lg:visible flex justify-center space-x-3 text-base md:text-xl lg:text-xl roboto w-2/5">
      <NavItem href="/" class="rounded-lg bg-primary hover:scale-110 duration-500 transition-all my-auto py-1 md:py-0">INÍCIO</NavItem>
      <NavItem href="/news" class="rounded-lg bg-primary hover:scale-110 duration-500 transition-all my-auto py-1 md:py-0">NOVIDADES</NavItem>
      <NavItem href="/guides" class="rounded-lg bg-primary hover:scale-110 duration-500 transition-all my-auto py-1 md:py-0">GUIAS</NavItem>
      <NavItem href="/store" class="rounded-lg bg-primary hover:scale-110 duration-500 transition-all my-auto py-1 md:py-0">LOJA</NavItem>
    </ul>
    <div class="collapse lg:visible flex flex-row space-x-2">
      <router-link v-if="profile?.username" class="flex rounded-lg bg-white items-center hover:scale-110 duration-500 transition-all py-3" to="/profile">
        <img alt="profile" class="h-8 mx-2 px-1 rounded-md" :src="'https://mc-heads.net/avatar/' + profile?.skins[profile?.skins.length-1].replace('http://textures.minecraft.net/texture/', '') + '.png'"/>
      </router-link>
      <router-link v-else class="flex rounded-lg bg-white items-center hover:scale-110 duration-500 transition-all py-3" to="/login">
        <i class="pi pi-sign-in mx-4 px-2 text-black" style="font-size: 1.5rem;"/>
      </router-link>
      <router-link class="flex rounded-lg bg-discord items-center hover:scale-110 duration-500 transition-all py-3" to="/discord">
        <i class="pi pi-discord mx-4 px-2" style="font-size: 2rem;"/>
      </router-link>
      <Toast position="bottom-left" group="bl"/>
      <div class="flex rounded-lg bg-white items-center py-3 hover:bg-gray-200 cursor-pointer" @click="copyIp()">
        <span class="mx-4 px-2 text-primary font-roboto">ZENITERP.COM.BR</span>
      </div>
    </div>
    <div class="lg:hidden flex items-center my-2" @click="visible = !visible">
      <i class="pi pi-bars w-auto text-white my-auto" style="font-size: 2rem;"/>
    </div>
    <Drawer v-model:visible="visible" position="left" class="w-3/4">
      <div class="flex flex-col space-y-8">
        <div class="flex flex-col space-y-4">
          <Button label="Início" outlined severity="contrast" class="w-full text-center rounded-lg bg-primary hover:scale-110 duration-500 transition-all my-auto py-1 md:py-0"/>
          <Button label="Novidades" outlined severity="contrast" class="w-full text-center rounded-lg bg-primary hover:scale-110 duration-500 transition-all my-auto py-1 md:py-0"/>
          <Button label="Guias" outlined severity="contrast" class="w-full text-center rounded-lg bg-primary hover:scale-110 duration-500 transition-all my-auto py-1 md:py-0"/>
          <Button label="Loja" outlined severity="contrast" class="w-full text-center rounded-lg bg-primary hover:scale-110 duration-500 transition-all my-auto py-1 md:py-0"/>
        </div>
        <div class="flex flex-row space-x-2 justify-between">
          <router-link class="flex rounded-lg bg-discord items-center hover:scale-110 duration-500 transition-all py-3" to="/discord">
            <i class="pi pi-discord mx-4 px-2" style="font-size: 1.5rem;"/>
          </router-link>
          <Toast position="bottom-left" group="bl"/>
          <div class="flex rounded-lg bg-white items-center py-3 hover:bg-gray-200 cursor-pointer" @click="copyIp()">
            <span class="mx-4 px-2 text-primary font-roboto">ZENITERP.COM.BR</span>
          </div>
        </div>
      </div>
    </Drawer>
  </nav>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Baskervville+SC&display=swap');
h1 {
  font-family: "Baskervville SC", serif;
  font-weight: 400;
  font-style: normal;
}
</style>

<script setup>
import Drawer from 'primevue/drawer';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import NavItem from '@/components/Navbar/NavItem';
import { ref, computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useGetProfile } from '@/services/profile';
import { useStore } from 'vuex';

const store = useStore();
const toast = useToast();
const visible = ref(false);

const profile = computed(() => {
  return store.getters.profile;
});

if (!profile.value?.username) useGetProfile();

async function copyIp() {
  try {
    await navigator.clipboard.writeText('zeniterp.com.br');
    toast.add({ severity: 'success', summary: 'Copiado', detail: 'O IP foi copiado para a área de transferência.', life: 3000, group: 'bl' });
  } catch($e) {
    toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível copiar o IP.', life: 3000, group: 'bl' });
  }
}
</script>